import { createBrowserHistory } from "history";
import React from "react";

import {
    AddonAttributes,
    CloudAttributes,
    DeviceAttributes,
    SiteAttributes,
    UserAttributes,
    UserCloudAttributes,
    UserSitePermission
} from "app/models";
import { VpcOptions } from "app/theme";
import { getEmbeddedJson } from "app/utils";

export interface ExternalLinks {
    help: string | null;
    privacyPolicy: string | null;
}

export type PageLevel = "cloud" | "site" | "device";

interface StaticContext {
    environment: "production" | "integration" | "development";
    pageLevel: PageLevel;
    appVersion: string;
    csrfToken: string;
    googleMapsKey: string;
    externalLinks: ExternalLinks;
    user: UserAttributes;
    theme: VpcOptions;
}

export interface DynamicContext {
    cloud: CloudAttributes;
    userCloud: UserCloudAttributes;
    permissions: UserSitePermission[] | true;
    site?: SiteAttributes;
    enabledAddons?: string[];
    device?: DeviceAttributes;
    uploadsUrl: string;
    addon?: AddonAttributes;
}

export interface PartialContext extends Partial<DynamicContext>, StaticContext {
}

export interface CloudContext extends DynamicContext, StaticContext {
}

export interface SiteContext extends PartialContext {
    site: SiteAttributes;
    enabledAddons: string[];
}

export interface AddonContext extends PartialContext {
    addon: AddonAttributes;
}

export interface DeviceContext extends PartialContext {
    device: DeviceAttributes;
}

export interface UrlParams {
    cloudId?: string;
    siteId?: string;
    deviceId?: string;
}

export const staticContext: StaticContext = getEmbeddedJson("context");
const legacyGlobalVars: any = getEmbeddedJson("global");

export const pageContext = React.createContext(staticContext as PartialContext);
export const browserHistory = createBrowserHistory();

let urlParamsCache: UrlParams | undefined;

export function getUrlParams(): UrlParams {
    if (urlParamsCache) {
        return urlParamsCache;
    }

    // Cache URL params until next tick
    window.setTimeout(() => urlParamsCache = undefined, 0);

    const pathname = window.location.pathname;

    const matchCloud = pathname.match(/\/cloud\/(\d+)/);
    const matchSite = pathname.match(/\/site\/(\d+)/);
    const matchDevice = pathname.match(/\/device\/(\d+)/);

    let cloudId, siteId, deviceId;

    if (legacyGlobalVars) {
        cloudId = legacyGlobalVars.getCloudId;
    }

    if (matchCloud) {
        cloudId = matchCloud[1];
    }

    if (matchSite) {
        siteId = matchSite[1];
    }

    if (matchDevice) {
        deviceId = matchDevice[1];
    }

    if (!cloudId) {
        throw new Error("Could not determine cloud ID by looking at global JSON or page URL");
    }

    urlParamsCache = {
        cloudId,
        siteId,
        deviceId,
    };

    return urlParamsCache;
}

function setPageLevel(context: StaticContext) {
    const urlParams = getUrlParams();

    if (urlParams.deviceId) {
        context.pageLevel = "device";
    } else if (urlParams.siteId) {
        context.pageLevel = "site";
    } else {
        context.pageLevel = "cloud";
    }
}

setPageLevel(staticContext);

interface Props {
    dynamicContext?: DynamicContext;
    children: React.ReactNode;
}

export const ContextProvider = (props: Props) => {
    const Provider = pageContext.Provider;
    const context = Object.assign({}, staticContext, props.dynamicContext || {});

    setPageLevel(context);

    return (
        <Provider value={context}>
            {props.children}
        </Provider>
    );
};
