export type AddonPlanType = "none" | "single" | "multi";
export type AddonSuspendType = "CLOUD_SUSPENDED" | "ADDONS_SUSPENDED";

export interface AddonAttributes {
    id: string;
    name: string;
    plan_type: AddonPlanType;
}

export class Addon {
    readonly id: string;
    readonly name: string;
    readonly planType: AddonPlanType;

    constructor(attrs: AddonAttributes) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.planType = attrs.plan_type;
    }
}

export default Addon;