import { useContext, useEffect } from "react";

import { pageContext } from "app/page/ContextProvider";
import { importExternalScript } from "app/utils";

const GTM_ID = "GTM-P34HJL9";

let gtmImported = false;

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js"
});

function loadAnalytics(): void {
    if (gtmImported) {
        return;
    }

    importExternalScript(
        "https://www.googletagmanager.com/gtm.js?id=" + GTM_ID + "&l=dataLayer"
    );

    gtmImported = true;
}

/**
 * The reason this is a component is that we want to load
 * it AFTER the page has rendered. Otherwise it would compete
 * with page content for internet bandwidth if we were to load
 * it from bootstrap.ts for example.
 */
export default function GoogleTagManager() {
    const context = useContext(pageContext);

    useEffect(() => {
        if (context.cloud && context.environment === "production") {
            loadAnalytics();
        }
    });

    return null;
}