import Site from "./Site";

// This should be called DeviceConfigInheritancePolicy but hey...
export type DeviceType = "NET_ENTERPRISE" | "NET_WISP";

export type DeviceProductType = "wifi"
    | "metrolinq"
    | "switch"
    | "meshlinq"
    | "glinq";

export type DeviceRegistrationStatus = "STATE_UNKNOWN"
    | "STATE_PENDING_AUTH"
    | "STATE_PENDING_REG"
    | "STATE_REGISTERED"
    | "STATE_REREG"
    | "STATE_UNKNOWN_PROD_ID"
    | "STATE_SUSPENDED"
    | "STATE_REG_FAILED";

export type DeviceHealth = "HEALTH_UNKNOWN"
    | "HEALTH_NORMAL"
    | "HEALTH_WARNING"
    | "HEALTH_CRITICAL";

export type DeviceOnlineStatus = "STATUS_UNKNOWN"
    | "STATUS_ONLINE"
    | "STATUS_OFFLINE"
    | "STATUS_SKIPPED_HB";

export interface DeviceAttributes {
    id: string;
    name: string;
    site_id: string;
    fw: string | null;
    mgmtd_version: string | null;
    sn: string;
    mac: string;
    type: DeviceType;
    hostname: string | null;
    product_brand_id: string;
    suspend_config: boolean;
    allow_config: boolean;
    config_saved_at: string | null;
    last_contact: string | null;
    health_id: DeviceHealth;
    status_id: DeviceOnlineStatus;
    state_id: DeviceRegistrationStatus;
    blocked: boolean;
    decomm: boolean;

    // Extra attributes: not columns in the device table but can be present
    config_type?: string | null;
    product_id?: string;
    product_type?: DeviceProductType;
}

export class Device {
    readonly id: string;
    readonly name: string;
    readonly siteId: string;
    readonly fw?: string;
    readonly mgmtdVersion?: string;
    readonly sn: string;
    readonly mac: string;
    readonly hostname?: string;
    readonly allow_config: boolean;
    readonly productBrandId: string;
    readonly blocked: boolean;
    readonly decomm: boolean;

    readonly inheritsConfig: boolean;
    readonly configSuspended: boolean;
    readonly health: DeviceHealth;
    readonly registrationStatus: DeviceRegistrationStatus;
    readonly onlineStatus: DeviceOnlineStatus;

    readonly configSavedAt?: Date;

    private readonly _configType?: string | null;
    private readonly _productId?: string;
    private readonly _productType?: DeviceProductType;

    private readonly _site?: Site;

    private _firmwareVersion?: string;
    private _firmwareRevision?: number;

    constructor(attrs: DeviceAttributes, site?: Site) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.siteId = String(attrs.site_id);

        if (attrs.fw !== null) {
            this.fw = attrs.fw;
        }

        if (attrs.mgmtd_version !== null) {
            this.mgmtdVersion = attrs.mgmtd_version;
        }

        this.sn = attrs.sn;
        this.mac = attrs.mac;

        if (attrs.hostname !== null) {
            this.hostname = attrs.hostname;
        }

        this.allow_config = attrs.allow_config;
        this.productBrandId = String(attrs.product_brand_id);

        if (attrs.product_id !== undefined) {
            this._productId = String(attrs.product_id);
        }

        this.health = attrs.health_id;
        this.registrationStatus = attrs.state_id;
        this.onlineStatus = attrs.status_id;

        this.inheritsConfig = (attrs.type === "NET_ENTERPRISE");
        this.configSuspended = attrs.suspend_config;

        this.blocked = attrs.blocked;
        this.decomm = attrs.decomm;

        if (attrs.config_saved_at !== null) {
            this.configSavedAt = new Date(attrs.config_saved_at + "Z");
        }

        this._productType = attrs.product_type;
        this._configType = attrs.config_type;

        this._site = site;
    }

    // Backwards compatibility property getters (deprecated)

    /** @deprecated use Device#inheritsConfig instead */
    get type(): DeviceType {
        console.warn("Device#type is deprecated: use Device#inhertitsConfig instead.");
        return this.inheritsConfig ? "NET_ENTERPRISE" : "NET_WISP";
    }

    /** @deprecated use Device#health instead */
    get healthId() {
        console.warn("Device#healthId is deprecated: use Device#health instead.");
        return this.health;
    }

    /** @deprecated use Device#onlineStatus instead */
    get statusId() {
        console.warn("Device#statusId is deprecated: use Device#onlineStatus instead.");
        return this.onlineStatus;
    }

    /** @deprecated use Device#registrationStatus instead */
    get stateId() {
        console.warn("Device#stateId is deprecated: use Device#registartionStatus instead.");
        return this.registrationStatus;
    }

    get configEnabled() {
        return this.allow_config;
    }

    get suspendConfig() {
        console.warn("Device#suspendConfig is deprecated: use Device#configSuspended isntead.");
        return this.configSuspended;
    }

    // Property getters

    get configType() {
        if (this._configType === undefined) {
            throw new Error("Device#configType hasn't been initialized");
        }

        return this._configType;
    }

    get brandId() {
        return this.productBrandId;
    }

    get productId() {
        if (this._productId === undefined) {
            throw new Error("Device#productId hasn't been initialized");
        }

        return this._productId;
    }

    get productType() {
        if (this._productType === undefined) {
            throw new Error("Device#productType hasn't been initialized");
        }

        return this._productType;
    }

    get firmwareVersion() {
        if (this.fw === undefined) {
            throw new Error("device firmware version not available");
        }

        if (this._firmwareVersion === undefined) {
            this._firmwareVersion = this.fw.split("-")[0];
        }

        return this._firmwareVersion;
    }

    get firmwareRevision() {
        if (this.fw === undefined) {
            throw new Error("device firmware revision not available");
        }

        if (this._firmwareRevision === undefined) {
            this._firmwareRevision = parseInt(this.fw.split("-")[1], 10);
        }

        return this._firmwareRevision;
    }

    get site() {
        if (this._site === undefined) {
            throw new Error("Device#site hasn't been initialized");
        }

        return this._site;
    }

    // Utility methods

    isOnline() {
        return !this.blocked
            && !this.decomm
            && [ "STATUS_ONLINE", "STATUS_SKIPPED_HB" ].includes(this.onlineStatus);
    }
}

export default Device;
